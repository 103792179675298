<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Create Blanket Agreement</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text to="/sales/blanket-agreement">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
            <editor :templatesData="templatesData" :currencies="currencies" :initial="dataItem" @data="save"></editor>
          <!-- end -->
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import Editor from '../_components/editor.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({
    dataItem: {},
    currencies: [],
    templatesData: []
  }),
  methods: {
    save(data) {
      const url = "/blanketagreement";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/lease-agreement");
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then(res => {
          self.currencies = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
     getTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/recurringtransactiontemplates`)
        .then(res => {
          self.templatesData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getCurrencies();
    this.getTemplates();
  }
};
</script>